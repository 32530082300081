<template>
  <a-modal
    v-model:visible="show"
    centered
    :closable="false"
    title="Assign Organization & Role"
    :footer="null"
    destroyOnClose
  >
    <approve-user
      :organizations="organizationList"
      :roles="rolesList"
      :email="email"
      @close="handleToggleModal"
    >
    </approve-user>
  </a-modal>
  <a-table
    :columns="columns"
    :data-source="pendingUserList"
    bordered
    :scroll="{ y: '55vh', x: '900px' }"
  >
    <template #title>
      <a-typography-title :level="5">
        Pending Users: &nbsp;
        <a-tag color="blue">{{ pendingUserList.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'actions'">
        <div class="actions">
          <span>
            <a-button type="primary" @click="handleToggleModal(record.email)"
              >Approve
            </a-button>
          </span>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import ApproveUser from './ApproveUser.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'user-component',
  components: {
    ApproveUser,
  },
  inject: ['toast'],
  data() {
    return {
      columns: [
        {
          title: 'First Name',
          dataIndex: 'username',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Position',
          dataIndex: 'position',
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
        },
      ],
      show: false,
      organizationList: [],
      rolesList: [],
      email: null,
      loading: false,
    };
  },

  created() {
    this.getOrganizations();
    this.getPendingUsersList();
    this.getAllRoles();
  },
  computed: {
    ...mapGetters(['roles', 'pendingUserList', 'organizations']),
  },
  watch: {
    roles(value) {
      if (value.length >= 1) {
        this.rolesList = value.map((el) => ({
          value: el.id,
          label: el.name,
        }));
      }
    },
    organizations(value) {
      if (value.length >= 1) {
        this.organizationList = value.map((el) => ({
          value: el.Org_name,
          label: el.Org_name,
        }));
      }
    },
  },
  methods: {
    ...mapActions(['getAllRoles', 'getPendingUsersList', 'getOrganizations']),
    handleToggleModal(email) {
      if (email) {
        this.email = email;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style></style>
